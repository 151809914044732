

















































































































import {
  Vue,
  Component,
  Watch,
  PropSync,
} from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsStartAllianzViewModel
  from '@/vue-app/view-models/components/contract-savings/start/contract-savings-start-allianz-view-model';

// Domain
import { PersonEntity } from '@/modules/my-investment/person/domain/entities/person-entity';

@Component({ name: 'ContractSavingsStartAllianz' })
export default class ContractSavingsStartAllianz extends Vue {
  @PropSync('enableNext', { type: Boolean })
  synced_enable_next!: boolean;

  contract_savings_start_allianz_view_model = new ContractSavingsStartAllianzViewModel()

  @Watch('contract_savings_start_allianz_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loading', is_loading);
  }

  @Watch('contract_savings_start_allianz_view_model.person')
  onPersonChange(person: PersonEntity) {
    this.contract_savings_start_allianz_view_model.setInputsValues(person);
  }

  @Watch('contract_savings_start_allianz_view_model.isoCountries')
  onISOCountriesChange() {
    this.contract_savings_start_allianz_view_model.findDefaultNationality();
  }

  @Watch('contract_savings_start_allianz_view_model.confirm_data')
  watchConfirmData(new_confirm_data: boolean) {
    this.synced_enable_next = new_confirm_data;
  }

  async created() {
    this.contract_savings_start_allianz_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.contract_savings_start_allianz_view_model.initialize();
  }

  async nextStep() {
    const saved = await this.contract_savings_start_allianz_view_model.saveStep();
    return saved;
  }
}
