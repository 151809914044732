import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import {
  SearchISOCountriesQuery,
} from '@/modules/my-investment/catalogs/allianz/iso-country/application/queries';

// Domain
import {
  PersonEntity,
} from '@/modules/my-investment/person/domain/entities/person-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsStartAllianzViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  readonly getPersonQuery!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_ISO_COUNTRY_QUERY)
  readonly searchISOCountriesQuery!: SearchISOCountriesQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.start';

  readonly i18n_common_namespace = 'components.common.gender';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  small_screen: null | boolean = null;

  confirm_data = false;

  is_loading = true;

  inputs = {
    full_name: '',
    curp: '',
    nationality: '',
    birthday: '',
    gender: '',
    rfc: '',
    homoclave: '',
  };

  private on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      curp: '',
      gender: '',
      full_name: '',
      nationality: {
        codPais: '',
        descPais: '',
      },
      date_of_birth: '',
      confirm_personal_information: false,
    },
  };

  default_nationality = 'MÉXICO';

  exists_step = false;

  step_name = 'personal_information';

  investment_provider_id = '';

  investment_provider_name = 'allianz';

  get person() {
    return this.getPersonQuery.execute();
  }

  setInputsValues = (person: PersonEntity) => {
    this.inputs.full_name = `${person.name} ${person.last_name} ${person.second_last_name}`;
    this.inputs.curp = person.curp;
    this.inputs.nationality = person.nationality.name;
    this.inputs.birthday = person.date_of_birth;
    this.inputs.gender = i18n.tc(`${this.i18n_common_namespace}.${person.gender.name}`);
    this.inputs.rfc = person.rfc.slice(0, -3);
    this.inputs.homoclave = person.rfc.slice(-3, person.rfc.length);
  };

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  get isoCountries() {
    return this.searchISOCountriesQuery.execute();
  }

  findDefaultNationality() {
    return this.isoCountries.find(
      (iso_country) => iso_country.descPais === this.default_nationality,
    );
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    if (on_boarding_steps.length) {
      const search_step = on_boarding_steps.find(
        (step) => step.current_step === this.step_name,
      );
      if (search_step) {
        this.exists_step = true;
        this.confirm_data = search_step.payload.confirm_personal_information;
        this.on_boarding_step = search_step;
        this.setInputsDataFromStep();
      }
    }
  };

  setInputsDataFromStep() {
    this.inputs.full_name = this.on_boarding_step.payload.full_name;
    this.inputs.curp = this.on_boarding_step.payload.curp;
    this.inputs.nationality = this.on_boarding_step.payload.nationality;
    this.inputs.birthday = this.on_boarding_step.payload.date_of_birth;
    this.inputs.gender = this.on_boarding_step.payload.gender;
    this.inputs.rfc = this.on_boarding_step.payload.rfc;
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        curp: this.person.curp,
        gender: this.person.gender.name,
        full_name: `${this.person.name} ${this.person.last_name} ${this.person.second_last_name}`,
        date_of_birth: this.person.date_of_birth,
        nationality: {
          codPais: 'MX',
          descPais: 'México',
        },
        confirm_personal_information: this.confirm_data,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    this.verifyStep(steps);

    this.setInputsValues(this.person);

    this.is_loading = false;
  }
}
